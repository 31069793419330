/* TimeFitHomePage.css */
body, html {
    margin: 0;
    padding: 0;
    font-family: 'Arial', sans-serif;
    background-color: #f8f9fa; /* light background color */
  }
  
  .landingPage {
    /*background-image: url('./images/backgroundImage.webp');  Replace with the actual file name if different */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
  }
  
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background-color: #0052cc;
    color: #ffffff;
  }
  
  .nav-links a {
    color: #ffffff;
    text-decoration: none;
    margin: 0 15px;
  }
  
  .header {
    text-align: center;
    padding: 4rem 2rem;
    background-color: #f0f4f8;
  }
  
  .header h1 {
    margin-bottom: 0.5rem;
  }
  
  .header p {
    margin-bottom: 2rem;
  }
  
  .header button {
    padding: 10px 20px;
    font-size: 1rem;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .features-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding: 2rem;
  }
  
  .feature {
    background-color: #e7e9eb;
    padding: 1rem;
    border-radius: 8px;
  }
  
  #about {
    padding: 2rem;
    background-color: #ffffff;
  }
  
  footer {
    text-align: center;
    padding: 1rem;
    background-color: #f0f4f8;
  }

  .header {
     /* Apply the background image */
  background-image: url('./images/backgroundImage.webp'); /* Replace with the actual file name */
  background-size: cover; /* Cover the entire area of the div */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-position: center center; /* Center the image in the div */
    /* Other styles... */
    background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background to ensure text readability */
    padding: 4rem 2rem;
    text-align: center;
    background-size: 100% auto;
  }
  
  /* Styles for the images within sections */
  .landingPage img {
    width: 100%; /* This will make the image responsive, fitting the width of its container */
    height: auto; /* This will maintain the aspect ratio of the image */
    max-width: 600px; /* Adjust the maximum width as needed */
    display: block; /* Display block to remove bottom space under the image */
    margin: 0 auto; /* Center the image within its container */
  }
  
  /* Section styling */
  section {
    padding: 2rem;
    text-align: center;
  }
  