.serie-config {
    position: relative;
    border: 1px solid #ccc;
    padding: 15px;
    margin-top: 15px;
    border-radius: 5px;
    background-color: #f9f9f9;
}

.serie-config .row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.serie-config label {
    flex-grow: 1;
    margin-right: 10px;
}

.serie-config .dow-selector {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
}

.serie-config .dow-checkbox {
    margin-right: 3px;
    font-size: 0.8rem; /* 80% del tamaño de fuente del elemento padre */
}

/* Añade más estilos según sea necesario */
.serie-config .toggle-button {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px; /* Ajusta el tamaño según tus necesidades */
    height: 5px; /* Representa una línea horizontal */
    background-color: #000; /* Color de la línea */
    border: none;
    cursor: pointer;
    z-index: 1001
}


/* Añade más estilos según sea necesario */

