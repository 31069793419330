.custom-quill .ql-toolbar {
    min-width: 300px;
    width: auto; /* o el tamaño específico que prefieras */
    position: fixed;
    top: 10px; /* Ajusta según sea necesario */
    gap: 20px; /* Ajusta el espacio entre los elementos */
  }
  
  .editable-container {
    display: flex;
    flex-direction: column; /* Alinea los elementos en una columna */
    align-items: stretch; /* Alinea los elementos para que se estiren a lo ancho del contenedor */
  }

  .text-display {
    margin-top: 20px; /* Ajusta según sea necesario para crear espacio entre el panel de control y el texto */
    /* Más estilos para TextDisplay */
  }

  .print-hide {
    display: none !important;
  }
  