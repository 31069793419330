  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Fondo semi-transparente */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Asegúrate de que esté sobre otros elementos */
    
  }
  /*
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    max-width: 450px; 
    width: 100%;
    box-sizing: border-box;
  }*/
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    max-width: 450px;
    width: 100%; /* Reduce el ancho en pantallas pequeñas */
    margin: 10px; /* Añade un margen para no tocar los bordes de la pantalla */
    box-sizing: border-box;
    overflow-y: auto; /* Permite desplazamiento vertical si es necesario */
    font-family: 'Roboto', sans-serif;

  }
  .color-picker button.selected {
    border: 2px solid black; /* Resalta el color seleccionado */
  }
  
  /* Estilos adicionales para el formulario aquí */
  .color-picker {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 240px; /* Ajusta este valor según el tamaño de tus botones */
    margin: auto;
  }
  
  .color-picker button {
    width: 30px;  /* Ajusta el tamaño para que quepan 6 en una fila */
    height: 30px;
    border-radius: 50%;
    margin: 5px;
    border: none;
    cursor: pointer;
  }
  
  .color-picker button.selected {
    border: 2px solid black;
  }
  
  @media (max-width: 600px) {
    .modal-content {
      width: 95%; /* Aumenta el ancho en pantallas muy pequeñas */
      padding: 10px; /* Reduce el padding para ahorrar espacio */
    }
  
    .color-picker {
      max-width: 100%; /* Permite que el color picker use todo el ancho disponible */
    }
  
    /* Ajusta el tamaño de los botones del color-picker para pantallas pequeñas */
    .color-picker button {
      width: 24px;
      height: 24px;
      margin: 4px;
    }
  }
input[type="text"],
input[type="datetime-local"],
select {
    padding: 12px 10px;
    font-size: 16px; /* Tamaño de fuente accesible */
    width: 100%; /* Aprovecha el ancho disponible */
    box-sizing: border-box; /* Incluye padding y border en el ancho total */
}

input[type="checkbox"] {
    transform: scale(1.5); /* Aumenta el tamaño del checkbox */
    margin: 10px;
    vertical-align: middle;
}

/* custom-datepicker.css */
.custom-datepicker {
  padding: 12px 10px;
  font-size: 50px; /* Tamaño de fuente accesible */
  width: 100%; /* Aprovecha el ancho disponible */
  box-sizing: border-box; /* Incluye padding y border en el ancho total */
}
.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 100%; 
}

.date-picker-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
/* Archivo: EventForm.css */

/* Clase que replica los estilos del editor */
.formatted-editor-content {
  font-family: 'Arial', sans-serif; /* Usa la misma fuente que el editor */
  font-size: 16px; /* Usa un tamaño similar */
  line-height: 1.5; /* Asegura el mismo espacio entre líneas */
  color: #333; /* Ajusta el color del texto */
  padding: 10px; /* Aplica un espaciado interior similar */
  border: 1px solid #ccc; /* Mantén un borde */
  background-color: #fff; /* Fondo claro */
  max-width: 600px; /* Ancho máximo */
  max-height: 300px; /* Altura máxima */
  overflow-y: auto; /* Permitir scroll vertical */
  cursor: 'pointer';
  white-space: normal; /* Asegura que el texto se ajuste */
}

/* Update in your EventForm.css */
@media only screen and (max-width: 768px) {
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4); /* Semi-transparent background */
    z-index: 9999;
    display: flex;
    justify-content: center; /* Horizontally centers the content */
    align-items: center; /* Vertically centers the content */
  }
/*
  .modal-content {
    width: 95%;
    max-width: 480px; 
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    overflow-y: auto;
    max-height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
  }
*/
.modal-content {
  width: 100vw; /* Set the width to 100% of the viewport */
  height: 100vh; /* Set the height to 100% of the viewport */
  padding: 10px;
  background-color: white;
  border-radius: 0; /* Remove border radius for full-screen usage */
  box-shadow: none; /* Remove shadow for full-screen effect */
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

  .formatted-editor-content {
    border: 1px solid #ccc;
    padding: 7px;
    cursor: pointer;
    overflow-wrap: break-word;
    max-width: 100%;
    max-height: 150px;
    margin-bottom: 15px; /* Spacing at the bottom */
  }
}

