/* src/styles/dashboard.css */
.dashboard {
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 1000;
    cursor: grab;
    color: white;
  }
  
  .icon-and-counts {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .counts {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 10px;
  }
  
  .counts .stage, .counts .medalCount {
    font-size: 10px; /* Ajusta este tamaño según tus necesidades */
    background-color: rgba(0, 0, 0, 0.5);
    padding: 2px 5px;
    border-radius: 5px;
    margin: 2px 0;
  }
  
  .dashboard.collapsed {
    width: 80px; /* Ajusta según necesidades */
    height: 50px; /* Ajusta según necesidades */
    background-color: #007bff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .dashboard.expanded {
    background-color: #f9f9f9;
    color: black;
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    cursor: auto;
    width: auto; /* Permite que el dashboard se expanda para ajustarse al contenido */
    height: auto; /* Permite que el dashboard se expanda para ajustarse al contenido */
  }
  