/* miEstiloPersonalizado.css */
.rbc-current-time-indicator {
    background-color: red !important; /* Cambia 'red' por el color que prefieras */
    position: absolute;
    padding: 0px 2px; /* Reducir según sea necesario */
    z-index: 0;
    left: 0;
    right: 0;
    height: 1px;
    pointer-events: none;
    /* Puedes añadir o modificar más propiedades aquí */
  }
  .rbc-button-link {
    font-weight: bolder;
    font-size: 1.1rem;
  }
  .rbc-event-label {
    display: none;
  }
  .rbc-event {
    min-height: 10px; /* Ajusta según el tamaño mínimo necesario */
    padding: 2px 4px;
    font-size: 0.8rem;
    line-height: 1.2;
}

.rbc-day-slot .rbc-event, .rbc-day-slot .rbc-background-event {
  min-height: 10px !important; /* Forzar el uso de esta altura mínima */
}
