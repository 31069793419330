/* En tu archivo CSS */
.profile {
    text-align: center;
    padding: 1rem;
  }
  
  .profile img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  
  .profile h2 {
    font-size: 1rem;
    margin: 0.5rem 0;
  }
  
  .profile p {
    font-size: 0.8rem;
    margin: 0;
  }
  