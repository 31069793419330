/* Archivo: TextEditorModal.css */

/* Estilos personalizados para el editor en el modal */
.modal {
  display: none; /* Oculto por defecto */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%; /* Ajusta según tu diseño */
  max-height: 80vh; /* Altura máxima */
  overflow: auto;
  background-color: white;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 100001; /* Aumentar el valor para asegurar que el modal esté encima */
}

.modal.visible {
  display: block; /* Mostrar cuando isOpen es verdadero */
}

.custom-editor {
  font-size: 14px; /* Tamaño de fuente más pequeño */
  max-height: 50vh; /* Altura máxima del editor */
  overflow-y: auto; /* Scroll vertical */
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.close-button {
  padding: 8px 16px;
  background-color: #007bff;
  border: none;
  color: white;
  cursor: pointer;
}

.close-button:hover {
  opacity: 0.8;
}

/* Fondo semi-transparente */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Fondo oscuro semi-transparente */
  z-index: 9998; /* Debajo del modal, pero encima del contenido principal */
}

/* Archivo: TextEditorModal.css */

/* Archivo: TextEditorModal.css */

/* Contenedor del Editor */
.editor-container {
  font-size: 14px; /* Tamaño de fuente más pequeño */
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.editor-content {
  max-height: 50vh; /* Altura máxima del editor */
  overflow-y: auto; /* Scroll vertical */
  border: 1px solid #ccc;
  padding: 10px;
  background: #fff;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.close-button {
  padding: 8px 16px;
  background-color: #007bff;
  border: none;
  color: white;
  cursor: pointer;
}

.close-button:hover {
  opacity: 0.8;
}
/* Mobile-specific adjustments for the editor modal */
@media only screen and (max-width: 768px) {
  .modal {
    width: 100vw; /* 100% of the viewport width */
    /*height: 100vh; /* 100% of the viewport height */
    margin: 0; /* Remove any margin */
    padding: 0; /* Remove any padding */
    top: 0; /* Align to top */
    left: 0; /* Align to left */
    transform: none; /* Remove any transform properties */
  }


  .editor-wrapper {
    padding: 0;
    /*height: 100vh;*/
  }

  .editor-content {
    /*height: calc(100vh - 60px);
    width: 100vw; */
    /*height: 100vh;*/
    /*min-height: 200px;*/
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
    background-color: #fff;
  }

  .modal-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }

  .close-button {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
  }

  .close-button:hover {
    background-color: #d32f2f;
  }
}
